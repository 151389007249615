import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { MqttMessage } from '../models/MqttMessage';

@Injectable({
  providedIn: 'root',
})
export class MqttMessagesDataService {
  private mqttSubjects: { [key: string]: BehaviorSubject<MqttMessage> } = {};

  public setMessages(uid: string, message: MqttMessage) {
    this.mqttSubjects[uid].next(message);
  }

  public getMessages(uid: string) {
    return this.mqttSubjects[uid]?.asObservable();
  }

  public resetAllMessages() {
    const emptyMessage: MqttMessage = { Timestamp: '', Messages: [] };

    Object.values(this.mqttSubjects).forEach((subject) =>
      subject.next(emptyMessage),
    );
    Object.values(this.infoSources).forEach((subject) =>
      subject.next(emptyMessage),
    );
    Object.values(this.warnSources).forEach((subject) =>
      subject.next(emptyMessage),
    );
    Object.values(this.alarmSources).forEach((subject) =>
      subject.next(emptyMessage),
    );

    this.totalInfoMessages.next(emptyMessage);
    this.totalWarnMessages.next(emptyMessage);
    this.totalAlarmMessages.next(emptyMessage);
  }

  private errorSource = new BehaviorSubject<HttpErrorResponse>(
    new HttpErrorResponse({ error: null, status: 0, statusText: '' }),
  );
  public error = this.errorSource.asObservable();

  public updateError(error: HttpErrorResponse) {
    this.errorSource.next(error);
  }

  private infoSources: { [uid: string]: BehaviorSubject<MqttMessage> } = {};
  private warnSources: { [uid: string]: BehaviorSubject<MqttMessage> } = {};
  private alarmSources: { [uid: string]: BehaviorSubject<MqttMessage> } = {};

  public getInfoObservable(uid: string) {
    return this.infoSources[uid]?.asObservable();
  }

  public getWarnObservable(uid: string) {
    return this.warnSources[uid]?.asObservable();
  }

  public getAlarmObservable(uid: string) {
    return this.alarmSources[uid]?.asObservable();
  }

  public updateInfo(uid: string, message: MqttMessage) {
    this.infoSources[uid]?.next({
      Timestamp: message.Timestamp,
      Messages: message.Messages.filter(
        (signal) => signal.Class.toLowerCase() === 'info',
      ),
    });

    this.totalInfoMessages.next({
      Timestamp: message.Timestamp,
      Messages: Object.values(this.infoSources)
        .map((source) => source.value.Messages)
        .flat(),
    });
  }

  public updateWarn(uid: string, message: MqttMessage) {
    this.warnSources[uid]?.next({
      Timestamp: message.Timestamp,
      Messages: message.Messages.filter(
        (signal) => signal.Class.toLowerCase() === 'warnung',
      ),
    });

    this.totalWarnMessages.next({
      Timestamp: message.Timestamp,
      Messages: Object.values(this.warnSources)
        .map((source) => source.value.Messages)
        .flat(),
    });
  }

  public updateAlarm(uid: string, message: MqttMessage) {
    this.alarmSources[uid]?.next({
      Timestamp: message.Timestamp,
      Messages: message.Messages.filter(
        (signal) => signal.Class.toLowerCase() === 'alarm',
      ),
    });

    this.totalAlarmMessages.next({
      Timestamp: message.Timestamp,
      Messages: Object.values(this.alarmSources)
        .map((source) => source.value.Messages)
        .flat(),
    });
  }

  createMessage(uid: string) {
    this.mqttSubjects[uid] = new BehaviorSubject<MqttMessage>(
      {} as MqttMessage,
    );

    this.infoSources[uid] = new BehaviorSubject<MqttMessage>({
      Timestamp: '',
      Messages: [],
    });

    this.warnSources[uid] = new BehaviorSubject<MqttMessage>({
      Timestamp: '',
      Messages: [],
    });

    this.alarmSources[uid] = new BehaviorSubject<MqttMessage>({
      Timestamp: '',
      Messages: [],
    });
  }

  private totalInfoMessages = new BehaviorSubject<MqttMessage>({
    Timestamp: '',
    Messages: [],
  });

  private totalWarnMessages = new BehaviorSubject<MqttMessage>({
    Timestamp: '',
    Messages: [],
  });

  private totalAlarmMessages = new BehaviorSubject<MqttMessage>({
    Timestamp: '',
    Messages: [],
  });

  public getTotalInfoObservable() {
    return this.totalInfoMessages.asObservable();
  }

  public getTotalWarnObservable() {
    return this.totalWarnMessages.asObservable();
  }

  public getTotalAlarmObservable() {
    return this.totalAlarmMessages.asObservable();
  }
}
